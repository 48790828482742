import React from 'react'
import Link from 'next/link'
import {useRouter} from 'next/router'

type Props = {
  link?: any
  extraClass?: string
  children: React.ReactNode
  locale?: string
  onClick?: () => void
}

const GenericLink = ({
  link,
  extraClass = '',
  children,
  locale,
  onClick,
}: Props) => {
  const router = useRouter()
  if (!link) {
    return <>{children}</>
  }
  let actualUrl: string | undefined
  if (link.uri?.alias) {
    if (link.uri.bundle === 'homepage_jobs') {
      actualUrl = '/'
    } else if (
      router.locale &&
      link.uri.alias.includes(router.locale.toLowerCase())
    ) {
      actualUrl = link.uri.alias.replace(`${router.locale.toLowerCase()}/`, '')
    } else {
      actualUrl = link.uri.alias
    }
  } else if (link.alias) {
    actualUrl = link.alias
  } else if (link.uri?.uri) {
    actualUrl = link.uri.uri
  } else {
    actualUrl = link
  }

  const gethref = () => {
    const url = actualUrl ?? link.uri?.uri ?? '/'
    const PUBLICURLHEAD = 'https://'
    let newUrl = ''
    if (url.includes(PUBLICURLHEAD)) {
      const urlWithoutHttps = url.split(PUBLICURLHEAD)[1].replace('//', '/')
      newUrl = `${PUBLICURLHEAD}${urlWithoutHttps}`
    } else {
      newUrl = url.replace('//', '/')
    }

    return newUrl
  }

  return (
    <Link
      locale={locale}
      passHref
      href={gethref()}
      className={extraClass ?? ''}
      onClick={onClick}
      target={link.options?.attributes?.target}
      rel={
        link.options?.attributes?.target === '_blank'
          ? 'noopener noreferrer'
          : undefined
      }
    >
      {children}
    </Link>
  )
}

export default GenericLink
