import {useTranslations} from 'next-intl'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import striptags from 'striptags'
import {FooterMenuLink} from '../../../types/content-types'
import {DoormatMenuItem, SocialMediaMenuItem} from '../../../types/menus'
import Doormat from './Doormat'
import FooterNavigation from './FooterNavigation'

export interface FooterProps {
  footerMenuItems: FooterMenuLink[] | null
  doormatMenuItems: DoormatMenuItem[] | null
  socialMenuItems: SocialMediaMenuItem[] | null
}

const Footer: React.FC<FooterProps> = ({
  footerMenuItems,
  doormatMenuItems,
  socialMenuItems,
}: FooterProps) => {
  const currentYear = new Date().getFullYear()

  const t = useTranslations()
  return (
    <footer className="c-footer position-relative">
      <Doormat
        doormatMenuItems={doormatMenuItems}
        socialMenuItems={socialMenuItems}
      />
      <div className="c-footer__container bg-slate-gray-10 d-flex py-4 text-center text-lg-left">
        <Container fluid={true}>
          <Row className="my-0 flex-column-reverse flex-lg-row">
            <Col sm={{span: 12}} md={{span: 4}} xl={{span: 2}}>
              <span className="c-footer__brand text-tuna-gray-60 d-flex align-items-center text-center mt-3  mt-lg-0 w-100">
                {striptags(t.raw('footer_copyright'))} {currentYear}
              </span>
            </Col>
            <Col sm={{span: 12}} md={{span: 8}} xl={{span: 10}}>
              <div className="d-flex  justify-content-lg-center  h-100">
                <FooterNavigation footerMenuItems={footerMenuItems} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  )
}

export default Footer
