import React from 'react'
import Nav from 'react-bootstrap/Nav'
import {SocialMediaMenuItem} from '../../../types/menus'

export type Props = {
  socialMenuItems: SocialMediaMenuItem[] | null
}

const SocialMediaNav: React.FC<Props> = ({socialMenuItems}: Props) => {
  return (
    <Nav className="c-footer__doormat--socialMediaNavWrapper flex-lg-row">
      <span className="c-footer__doormat--navItem c-footer__doormat--navHeader w-100">
        Follow us
      </span>
      <Nav className="c-footer__doormat--socialMediaNav">
        {socialMenuItems
          ?.filter(social => social.entity_id.enabled)
          .sort((a, b) => a.entity_id.weight! - b.entity_id.weight!)
          .map(socialMenuItem => (
            <a
              key={socialMenuItem.entity_id.id}
              className="pr-3 c-footer__doormat--navItem"
              href={socialMenuItem.entity_id.link.uri.uri}
              aria-label={socialMenuItem.entity_id.field_social_type}
              target={socialMenuItem.entity_id.link.options?.attributes.target}
            >
              <span
                className={`c-footer__doormat--socialMediaNav-icon c-footer__doormat--socialMediaNav-${socialMenuItem.entity_id.field_social_type}`}
              />
            </a>
          ))}
      </Nav>
    </Nav>
  )
}

export default SocialMediaNav
