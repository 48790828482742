import { Header } from './header/Header';
import Footer from './footer/Footer';
import Head from 'next/head';
import { Node } from '../../types/content-types';
import { Menu } from '../../types/api-types';
import { useRouter } from 'next/router';
import { useSessionstorage } from '../../utils/hooks';
import { useEffect } from 'react';

export interface LayoutProps {
  menus: Menu | null;
  children?: React.ReactNode;
  resource?: Node | null;
  extraClassName?: string;
}

export function Layout({
  menus,
  children,
  resource,
  extraClassName,
}: LayoutProps) {
  const router = useRouter();

  const canonical = resource?.metatag_normalized?.find(
    metatag => metatag.attributes?.rel === 'canonical'
  );
  const metaTitle = resource?.metatag_normalized?.find(
    metatag => metatag.attributes?.name === 'title'
  );
  const metaDescription = resource?.metatag_normalized?.find(
    metatag => metatag.attributes?.name === 'description'
  );
  const ogTitle = resource?.metatag_normalized?.find(
    metatag => metatag.attributes?.property === 'og:title'
  );
  const ogDescription = resource?.metatag_normalized?.find(
    metatag => metatag.attributes?.property === 'og:description'
  );
  const ogImage = resource?.metatag_normalized?.find(
    metatag => metatag.attributes?.property === 'og:image'
  );
  const hrefLang = resource?.metatag_normalized?.filter(
    metatag => !!metatag.attributes.hreflang
  );
  const robots = resource?.metatag_normalized?.find(
    metatag => metatag.attributes?.name === 'robots'
  );

  const newUtmSource = router.query?.utm_source as string | null;
  const newUtmMedium = router.query?.utm_medium as string | null;

  const [_, setSrc] = useSessionstorage('utm_source', '');
  const [, setMedium] = useSessionstorage('utm_medium', '');

  useEffect(() => {
    const oldUtmSource = sessionStorage.getItem('utm_source');
    const oldUtmMedium = sessionStorage.getItem('utm_medium');

    if (newUtmSource || newUtmMedium) {
      if (oldUtmSource || oldUtmMedium) {
        sessionStorage.removeItem('utm_medium');
        sessionStorage.removeItem('utm_source');
      }

      if (newUtmSource) {
        setSrc(newUtmSource);
      }

      if (newUtmMedium) {
        setMedium(newUtmMedium);
      }
    }
  }, [setMedium, setSrc, newUtmSource, newUtmMedium]);

  // Check for 'paragraph--job_overview_two_panes' in 'field_paragraphs' or 'field_job_overview_two_panes'
  const hasJobOverviewTwoPanes =
    resource?.field_paragraphs?.some(
      paragraph => paragraph.type === 'paragraph--job_overview_two_panes'
    ) || resource?.field_job_overview_two_panes?.type === 'paragraph--job_overview_two_panes';


  return (
    <div className={extraClassName}>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link rel="icon" href={'/favicon.ico'} />
        {!hasJobOverviewTwoPanes && canonical ? (
          <link
            rel={canonical.attributes.rel}
            href={
              resource?.type === 'node--homepage_jobs'
                ? `${process.env.NEXT_PUBLIC_EXTERNAL_DATA_URL}/${
                    resource?.langcode ?? ''
                  }`
                : `${process.env.NEXT_PUBLIC_EXTERNAL_DATA_URL}${canonical.attributes.href}`
            }
          />
        ) : null}
        {metaTitle ? <title>{metaTitle.attributes.content}</title> : null}
        {ogTitle ? (
          <meta
            content={ogTitle.attributes.content ?? ''}
            property={ogTitle.attributes.property}
          />
        ) : null}
        {ogImage ? (
          <meta
            content={`${process.env.NEXT_PUBLIC_EXTERNAL_DATA_URL}${ogImage.attributes.content}`}
            property="og:image"
          />
        ) : null}
        {metaDescription ? (
          <meta
            content={metaDescription.attributes.content ?? ''}
            name={metaDescription.attributes.name}
          />
        ) : null}
        {ogDescription ? (
          <meta
            content={ogDescription.attributes.content ?? ''}
            property={ogDescription.attributes.property}
          />
        ) : null}
        {!hasJobOverviewTwoPanes && hrefLang && hrefLang.length > 0 ? (
          <>
            {hrefLang
              .filter(el => el.attributes.hreflang !== 'x-default')
              .map(el => (
                <link
                  hrefLang={el.attributes.hreflang}
                  rel={el.attributes.rel}
                  key={el.attributes.href}
                  href={
                    resource?.type === 'node--homeresource'
                      ? `${process.env.NEXT_PUBLIC_EXTERNAL_DATA_URL}/${
                          el.attributes.href?.split('/')[1]
                        }`
                      : `${process.env.NEXT_PUBLIC_EXTERNAL_DATA_URL}${el.attributes.href}`
                  }
                />
              ))}
          </>
        ) : null}
        {robots ? (
          <meta name="robots" content={robots.attributes.content ?? ''} />
        ) : null}
      </Head>
      <Header
        mainMenuItems={menus?.mainMenu ?? null}
        functionalMenuItems={
          menus?.functionalMenu && menus?.functionalMenu?.length !== 0
            ? menus!.functionalMenu
            : null
        }
        siteSwitchMenuItems={
          resource?.type === 'application-form'
            ? null
            : menus?.siteSwitch ?? null
        }
        topNavigationMenuItems={menus?.topMenu ?? null}
        urlTranslations={resource?.path_translations}
        showLanguageSwitch={
          resource?.type !== 'node--application-form' &&
          resource?.type !== 'node--vacancy'
        }
      />

      <main>{children}</main>
      <Footer
        footerMenuItems={menus?.footerMenu ?? null}
        doormatMenuItems={menus?.doormatMenu ?? null}
        socialMenuItems={menus?.socialMenu ?? null}
      />
    </div>
  );
}
