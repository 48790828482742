import React, {useState} from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import {CSSTransition} from 'react-transition-group'
import Image from 'next/image'
import Link from 'next/link'
import {useRouter} from 'next/router'
import GenericLink from '../../shared/Link'
import FunctionalNavigation from './FunctionalNavigation'
import NavigationHighlight from './NavigationHighlight'
import ConditionalWrapper from '../../shared/ConditionalWrapper'
import logo from '../../../public/sdworx_logo.svg'
import {MenuLink} from '../../../types/content-types'
import {HeaderProps} from './Header'

interface MainNavigationProps
  extends Omit<HeaderProps, 'urlTranslations' | 'siteSwitchMenuItems'> {
  expanded: boolean
  // eslint-disable-next-line no-unused-vars
  setExpanded: (expandedState: boolean) => void
  // eslint-disable-next-line no-unused-vars
  setShadow?: (val: boolean) => void
}

const MainNavigation = ({
  mainMenuItems,
  functionalMenuItems,
  topNavigationMenuItems,
  expanded,
  setExpanded,
  setShadow,
}: MainNavigationProps) => {
  const [activeMenu, setActiveMenu] = useState('main')
  const [activeSubNav, setActiveSubNav] = useState('')
  const router = useRouter()
  React.useEffect(() => {
    const handleRouteChange = () => {
      setActiveSubNav('')
      setActiveMenu('main')
      setExpanded(false)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events, setExpanded])

  const mainMenuItemsFiltered = mainMenuItems?.filter(
    el => el.entity_id.enabled,
  )
  const topLevelMainMenuItems = mainMenuItemsFiltered?.filter(
    el => !el.entity_id.parent,
  )

  const handleMenuItemClick = (menuItem: MenuLink | null = null) => {
    if (menuItem && menuItem.children) {
      setActiveMenu(menuItem.id)
    } else {
      setActiveMenu('main')
      setExpanded(false)
    }
  }

  const handleToggleClick = () => {
    setActiveMenu('main')
    setExpanded(!expanded)
  }

  const handleMenuItemHoverOn = (item: any[] | undefined) => {
    if (item && item.length > 0) {
      setShadow?.(true)
    } else {
      setShadow?.(false)
    }
  }

  const renderNavigationItem = (
    navigationItem: MenuLink,
    withWrapper: boolean = false,
    thirdStage: boolean = false,
  ) => {
    const enabledChildren = mainMenuItemsFiltered?.filter(el =>
      el?.entity_id.parent?.includes(navigationItem.id),
    )

    return (
      <ConditionalWrapper
        condition={withWrapper}
        wrapper={(childrenPassed: any) => (
          <div className="px-4 py-2 d-flex justify-content-between w-full">
            {childrenPassed}
          </div>
        )}
        key={navigationItem.id}
      >
        <Nav.Item
          className={
            enabledChildren && enabledChildren.length > 0
              ? 'hasChildren'
              : 'border-0 hasNoChildren'
          }
          key={navigationItem.id}
          onClick={() => {
            handleMenuItemClick(navigationItem)
          }}
        >
          <GenericLink
            extraClass={`ma w-100 ${
              enabledChildren?.length === 0 && thirdStage
                ? 'font-weight-light grandchild'
                : 'font-weight-bold'
            }`}
            link={navigationItem.link}
          >
            {navigationItem.title}
          </GenericLink>
        </Nav.Item>
      </ConditionalWrapper>
    )
  }

  const renderMainNavigation = (menuItem: MenuLink) => {
    const enabledChildren = mainMenuItemsFiltered?.filter(el =>
      el.entity_id.parent?.includes(menuItem.id),
    )

    const wrapGroup = !enabledChildren?.some(child =>
      mainMenuItemsFiltered?.some(el =>
        el.entity_id.parent?.includes(child.entity_id.id),
      ),
    )
    return (
      <Nav
        className={`c-navbar__subnav ${
          menuItem.id === activeSubNav ? 'active' : ''
        }`}
        key={menuItem.id}
      >
        <div className="d-flex flex-row py-4">
          <ConditionalWrapper
            condition={wrapGroup}
            wrapper={(childrenPassed: any) => (
              <div className="d-flex flex-column flex-wrap justify-content-start align-items-start">
                {childrenPassed}
              </div>
            )}
          >
            {enabledChildren
              ?.sort((a, b) => a.entity_id.weight! - b.entity_id.weight!)
              ?.map(firstLevel => {
                const enabledGrandChildren = mainMenuItemsFiltered?.filter(el =>
                  el.entity_id.parent?.includes(firstLevel.entity_id.id),
                )
                return (
                  <React.Fragment key={firstLevel.entity_id.id}>
                    <div
                      className={`c-navbar__subnav-group ${
                        enabledChildren.length === 0 ? 'd-flex' : ''
                      }`}
                    >
                      {renderNavigationItem(firstLevel.entity_id)}

                      {enabledGrandChildren
                        ?.sort(
                          (a, b) => a.entity_id.weight! - b.entity_id.weight!,
                        )
                        .map(secondLevel =>
                          renderNavigationItem(
                            secondLevel.entity_id,
                            false,
                            true,
                          ),
                        )}
                    </div>
                  </React.Fragment>
                )
              })}
          </ConditionalWrapper>
        </div>
        {menuItem.field_highlight_media ? (
          <NavigationHighlight item={menuItem} />
        ) : null}
      </Nav>
    )
  }

  const renderMobileNavigation = (
    childrenForMobile: MenuLink,
    parentId: string,
  ) => {
    const enabledChildren = mainMenuItemsFiltered?.filter(el =>
      el.entity_id.parent?.includes(childrenForMobile.id),
    )

    return (
      <CSSTransition
        in={activeMenu === childrenForMobile.id}
        timeout={250}
        classNames="c-navbar__menu-slide"
        unmountOnExit={true}
        key={`${parentId}-${childrenForMobile.id}`}
      >
        <div>
          <Nav className="c-navbar__subnav-mobile">
            {childrenForMobile ? (
              <Nav.Item className="c-navbar__subnav-mobile-title">
                <button
                  onClick={() => setActiveMenu(parentId)}
                  aria-label="Back to parent"
                  type="button"
                />
                {childrenForMobile.title}
              </Nav.Item>
            ) : null}
            <div className="c-navbar__subnav-mobile-group">
              {enabledChildren
                ?.sort((a, b) => a.entity_id.weight! - b.entity_id.weight!)
                .map(child => {
                  const enabledGrandChildren = mainMenuItemsFiltered?.filter(
                    el => el.entity_id.parent?.includes(child.entity_id.id),
                  )
                  return (
                    <React.Fragment key={child.entity_id.id}>
                      <div
                        className={`c-navbar__subnav-group ${
                          enabledChildren.length === 0 ? 'd-flex' : ''
                        }`}
                      >
                        {renderNavigationItem(child.entity_id, true)}
                        <div className="d-block w-100">
                          {enabledGrandChildren
                            ?.sort(
                              (a, b) =>
                                a.entity_id.weight! - b.entity_id.weight!,
                            )
                            .map(secondLevel =>
                              renderNavigationItem(
                                secondLevel.entity_id,
                                true,
                                true,
                              ),
                            )}
                        </div>
                      </div>
                    </React.Fragment>
                  )
                })}
            </div>
          </Nav>
          {childrenForMobile.field_highlight_media ? (
            <NavigationHighlight item={childrenForMobile} />
          ) : null}
        </div>
      </CSSTransition>
    )
  }

  return (
    <Navbar
      expand="lg"
      variant="light"
      bg="white"
      className="c-navbar py-2"
      collapseOnSelect={true}
      onSelect={() => {
        setActiveMenu('main')
      }}
      expanded={expanded}
    >
      <Link href="/" passHref={true}>
        <Navbar.Brand className="ma">
          <Image
            width={188}
            height={48}
            alt="sdworx.jobs logo"
            src={logo}
            priority={true}
          />
        </Navbar.Brand>
      </Link>

      <Navbar.Toggle
        aria-controls="navbar-nav"
        className="close-btn"
        onClick={() => handleToggleClick()}
      >
        <></>
      </Navbar.Toggle>
      <Navbar.Collapse id="navbar-nav">
        <Link href="/" passHref={true}>
          <Navbar.Brand className="ma d-lg-none position-absolute pt-2 mt-1 pl-3">
            <Image
              width={281}
              height={48}
              alt="sdworx.jobs logo"
              src={logo}
              priority={true}
            />
          </Navbar.Brand>
        </Link>
        <div className="c-navbar__nav-desktop">
          <Nav className="ml-auto h-100 align-items-center">
            {topLevelMainMenuItems
              ?.sort((a, b) => a.entity_id.weight! - b.entity_id.weight!)
              .map(menuItem => {
                const enabledChildren = mainMenuItemsFiltered?.filter(el =>
                  el.entity_id.parent?.includes(menuItem.entity_id.id),
                )
                return (
                  <Nav.Item
                    className={`${
                      enabledChildren && enabledChildren.length! > 0
                        ? 'hasChildren'
                        : ''
                    }
                  `}
                    key={menuItem.entity_id.id}
                    onMouseEnter={() => {
                      setActiveSubNav(menuItem.entity_id.id)
                      handleMenuItemHoverOn(enabledChildren)
                    }}
                    onMouseLeave={() => {
                      setActiveSubNav('')
                      setShadow?.(false)
                    }}
                    onFocus={() => setActiveSubNav(menuItem.entity_id.id)}
                    onBlur={() => setActiveSubNav('')}
                  >
                    <GenericLink
                      extraClass={`ma ${
                        menuItem.entity_id.id === activeSubNav ? 'active' : ''
                      }`}
                      link={menuItem.entity_id.link}
                    >
                      {menuItem.entity_id.title}
                    </GenericLink>
                    {enabledChildren && enabledChildren.length! > 0 && (
                      <button
                        onClick={() => {
                          setActiveMenu(menuItem.entity_id.id)
                        }}
                        type="button"
                        className="c-navbar__nav-mobile--subNavBtn text-center border-0 d-lg-none"
                        aria-label="Open subnavigation"
                      >
                        +
                      </button>
                    )}
                    {enabledChildren &&
                      enabledChildren.length > 0 &&
                      renderMainNavigation(menuItem.entity_id)}
                  </Nav.Item>
                )
              })}
          </Nav>
          {functionalMenuItems ? (
            <FunctionalNavigation functionalMenuItems={functionalMenuItems} />
          ) : null}
        </div>
        <CSSTransition
          in={activeMenu === 'main'}
          timeout={500}
          classNames="c-navbar__menu-slide"
          unmountOnExit={true}
        >
          <div className="c-navbar__nav-mobile ">
            <Nav className="mr-auto h-100 align-items-center px-4">
              {topLevelMainMenuItems
                ?.sort((a, b) => a.entity_id.weight! - b.entity_id.weight!)
                .map(menuItem => {
                  const enabledChildren = mainMenuItemsFiltered?.filter(el =>
                    el.entity_id.parent?.includes(menuItem.entity_id.id),
                  )
                  return (
                    <Nav.Item
                      className={
                        enabledChildren && enabledChildren.length! > 0
                          ? 'hasChildren'
                          : ''
                      }
                      key={`csst-${menuItem.entity_id.id}`}
                    >
                      <div className="c-navbar__nav-mobile__innerWrapper d-flex justify-content-between w-full">
                        <GenericLink
                          extraClass="ma"
                          link={menuItem.entity_id.link}
                        >
                          {menuItem.entity_id.title}
                        </GenericLink>
                        {enabledChildren && enabledChildren.length > 0 ? (
                          <button
                            onClick={() => {
                              setActiveMenu(menuItem.entity_id.id)
                            }}
                            type="button"
                            className="c-navbar__nav-mobile--subNavBtn"
                            aria-label="Open subnavigation"
                          >
                            +
                          </button>
                        ) : null}
                      </div>
                      {enabledChildren
                        ? renderMainNavigation(menuItem.entity_id)
                        : null}
                    </Nav.Item>
                  )
                })}
            </Nav>
            {functionalMenuItems ? (
              <FunctionalNavigation functionalMenuItems={functionalMenuItems} />
            ) : null}
            <div className="c-navbar__subnav-mobile__top-nav-mobile px-4 py-2 mt-3">
              {topNavigationMenuItems && topNavigationMenuItems?.length > 0 ? (
                <>
                  {topNavigationMenuItems
                    ?.filter(el => el.entity_id.enabled)
                    .sort((a, b) => a.entity_id.weight! - b.entity_id.weight!)
                    .map(item => {
                      return (
                        <GenericLink
                          extraClass={'ctn-audience-link my-1'}
                          link={item.entity_id.link}
                          key={item.entity_id.id}
                        >
                          {item.entity_id.title}
                        </GenericLink>
                      )
                    })}
                </>
              ) : null}
            </div>
          </div>
        </CSSTransition>
        {topLevelMainMenuItems
          ?.sort((a, b) => a.entity_id.weight! - b.entity_id.weight!)
          .map(child => {
            const enabledChildren = mainMenuItemsFiltered?.filter(el =>
              el.entity_id.parent?.includes(child.entity_id.id),
            )
            if (enabledChildren && enabledChildren.length > 0) {
              return renderMobileNavigation(child.entity_id, 'main')
            }
            return null
          })}
      </Navbar.Collapse>
    </Navbar>
  )
}

export default MainNavigation
