import React from 'react'
import {
  FunctionalMenuItem,
  MainMenuItem,
  SiteSwitchMenuItem,
  TopNavigationMenuItem,
} from '../../../types/menus'
import MainNavigation from './MainNavigation'
import TopNavigation from './TopNavigation'

export interface HeaderProps {
  mainMenuItems: MainMenuItem[] | null
  siteSwitchMenuItems: SiteSwitchMenuItem[] | null
  topNavigationMenuItems: TopNavigationMenuItem[] | null
  functionalMenuItems: FunctionalMenuItem[] | null
  urlTranslations: any
  showLanguageSwitch?: boolean
}

export const Header = ({
  mainMenuItems,
  topNavigationMenuItems,
  functionalMenuItems,
  siteSwitchMenuItems,
  urlTranslations,
  showLanguageSwitch,
}: HeaderProps) => {
  const headerRef = React.useRef<HTMLElement>(null)
  const [expanded, setExpanded] = React.useState(false)
  const wrapperRef = React.useRef<HTMLDivElement>(null)
  const [shadow, setShadow] = React.useState(false)
  React.useEffect(() => {
    const sticky = headerRef.current?.offsetTop
    if (!expanded) {
      if (window.pageYOffset > sticky!) {
        if (!headerRef.current?.classList.contains('fixed-top')) {
          headerRef.current?.classList.add('fixed-top')
        }
      } else if (headerRef.current?.classList.contains('fixed-top')) {
        headerRef.current?.classList.remove('fixed-top')
      }
    }
    const handleScroll = () => {
      if (!expanded) {
        if (window.pageYOffset > sticky!) {
          if (!headerRef.current?.classList.contains('fixed-top')) {
            headerRef.current?.classList.add('fixed-top')
          }
        } else if (headerRef.current?.classList.contains('fixed-top')) {
          headerRef.current?.classList.remove('fixed-top')
        }
      }
    }
    if (headerRef.current) {
      window.addEventListener('scroll', handleScroll)
    }
    return () => window.removeEventListener('scroll', handleScroll)
  }, [headerRef, expanded])

  React.useEffect(() => {
    if (wrapperRef.current) {
      if (shadow) {
        wrapperRef.current?.classList.add('active')
      } else {
        if (wrapperRef.current?.classList.contains('active')) {
          wrapperRef.current?.classList.remove('active')
        }
      }
    }
  }, [shadow])
  return (
    <div className="c-navbar__overlay" ref={wrapperRef}>
      <TopNavigation
        siteSwitchMenuItems={siteSwitchMenuItems}
        urlTranslations={urlTranslations}
        topNavigationMenuItems={topNavigationMenuItems}
        showLanguageSwitch={showLanguageSwitch}
      />
      <header className={expanded ? 'fixed-top' : ''} ref={headerRef}>
        <MainNavigation
          mainMenuItems={mainMenuItems}
          functionalMenuItems={functionalMenuItems}
          topNavigationMenuItems={topNavigationMenuItems}
          expanded={expanded}
          setExpanded={setExpanded}
          setShadow={(val: boolean) => setShadow(val)}
        />
      </header>
    </div>
  )
}
