import {useState} from 'react'
import {v4 as uuidv4} from 'uuid'
import GenericLink from '../../shared/Link'
import {DrupalLink} from '../../../types/content-types'
import {SiteSwitchMenuItem, TopNavigationMenuItem} from '../../../types/menus'
import LanguageSwitch from './LanguageSwitch'

type Props = {
  siteSwitchMenuItems: SiteSwitchMenuItem[] | null
  urlTranslations: DrupalLink[] | null
  topNavigationMenuItems: TopNavigationMenuItem[] | null
  showLanguageSwitch?: boolean
}

const TopNavigation = ({
  siteSwitchMenuItems,
  urlTranslations,
  topNavigationMenuItems,
  showLanguageSwitch,
}: Props) => {
  const [activeState, setActiveState] = useState(0)

  return (
    <div className="c-top-navigation position-relative  justify-content-between align-items-center d-flex">
      <div className="ctn-audience-wrapper d-flex ">
        {siteSwitchMenuItems
          ? siteSwitchMenuItems
              .filter(el => el.entity_id.enabled)
              .sort((a, b) => a.entity_id.weight! - b.entity_id.weight!)
              .map((item, index) => {
                const id = uuidv4()
                return (
                  <GenericLink
                    extraClass={`ctn-audience-link ${
                      index === activeState
                        ? 'font-weight-bold position-relative d-inline-block active'
                        : 'font-weight-normal'
                    }`}
                    link={item.entity_id.link}
                    key={id}
                    onClick={() => setActiveState(index)}
                  >
                    {item.entity_id.title}
                  </GenericLink>
                )
              })
          : null}
      </div>

      <div className="ctn-top-bar-wrapper d-flex justify-content-between align-items-center">
        {topNavigationMenuItems && topNavigationMenuItems.length > 0 ? (
          <div className="d-none d-lg-block">
            {topNavigationMenuItems
              ?.filter(el => el.entity_id.enabled)
              .sort((a, b) => a.entity_id.weight! - b.entity_id.weight!)
              .map((item, index) => {
                const id = uuidv4()
                return (
                  <GenericLink
                    extraClass={`ctn-audience-link font-weight-normal ${
                      index === topNavigationMenuItems.length - 1 ? 'mr-0' : ''
                    }`}
                    link={item.entity_id.link}
                    key={id}
                    onClick={() => setActiveState(index)}
                  >
                    {item.entity_id.title}
                  </GenericLink>
                )
              })}
          </div>
        ) : null}
        {showLanguageSwitch ? (
          <LanguageSwitch urlTranslations={urlTranslations} />
        ) : null}
      </div>
    </div>
  )
}

export default TopNavigation
