import Button from 'react-bootstrap/Button'
import Nav from 'react-bootstrap/Nav'
import GenericLink from '../../shared/Link'
import {HeaderProps} from './Header'

type FunctionalMenuProps = Pick<HeaderProps, 'functionalMenuItems'>

const FunctionalNavigation = ({functionalMenuItems}: FunctionalMenuProps) => (
  <Nav className="c-navbar__functional-nav  d-flex px-3 mt-3 p-lg-0 mt-lg-0 h-100 align-items-center">
    {functionalMenuItems
      ?.filter(item => item.entity_id.field_functional_type === 'login')
      .map(item => (
        <Nav.Item
          className="c-navbar__functional-nav-login"
          key={item.entity_id.id}
        >
          <GenericLink extraClass="ma" link={item.entity_id.link}>
            {item.entity_id.title}
          </GenericLink>
        </Nav.Item>
      ))}
    {functionalMenuItems
      ?.filter(item => item.entity_id.field_functional_type === 'button')
      .sort((a, b) => a.entity_id.weight! - b.entity_id.weight!)
      .map(item => (
        <Button
          className="btn--orange c-navbar__functional-nav-button--orange border-0"
          key={item.entity_id.id}
        >
          <GenericLink extraClass="ma text-white" link={item.entity_id.link}>
            {item.entity_id.title}
          </GenericLink>
        </Button>
      ))}
  </Nav>
)

export default FunctionalNavigation
