import React from 'react'
import Nav from 'react-bootstrap/Nav'
import {FooterMenuLink} from '../../../types/content-types'
import GenericLink from '../../shared/Link'

type Props = {
  footerMenuItems?: FooterMenuLink[] | null
  lightVersion?: boolean
}

const FooterNavigation: React.FC<Props> = ({
  footerMenuItems,
  lightVersion = false,
}: Props) => (
  <Nav className="c-footer__nav my-auto">
    <ul
      className={`c-footer__nav-items d-block d-lg-flex flex-wrap  pl-0 m-0 w-100 justify-content-xl-start ${
        lightVersion ? '' : 'justify-content-lg-start'
      }`}
    >
      {footerMenuItems
        ?.filter(el => el.entity_id?.enabled)
        .sort((a, b) => a.entity_id?.weight! - b.entity_id?.weight!)
        .map(menuItem => (
          <li
            className="c-footer__nav-item d-block d-lg-inline-block text-left"
            key={menuItem.id}
          >
            <GenericLink extraClass="p-0 " link={menuItem.entity_id?.link}>
              {menuItem.entity_id?.title}
            </GenericLink>
          </li>
        ))}
    </ul>
  </Nav>
)

export default FooterNavigation
