import { useCallback, useState } from 'react'
import { useTranslations } from 'next-intl'
import flagBelgium from '../../../public/EU_Belgium_Flag_25x16.webp'
import flagNetherlands from '../../../public/EU_Netherlands_Flag_35x20.webp'
import Image from 'next/image'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { DrupalLink } from '../../../types/content-types'
import striptags from 'striptags'

const localeToFlag = {
  'nl-be': flagBelgium,
  'fr-be': flagBelgium,
  'en-be': flagBelgium,
  'nl-nl': flagNetherlands,
  'en-nl': flagNetherlands,
}

const languageSwitchMenuItems = [
  { label: 'Belgique | Français', locale: 'fr-be' },
  { label: 'België | Nederlands', locale: 'nl-be' },
  { label: 'Belgium | English', locale: 'en-be' },
  { label: 'Nederland | Nederlands', locale: 'nl-nl' },
  { label: 'Netherlands | English', locale: 'en-nl' },
]

type LanguageSwitchProps = {
  urlTranslations: DrupalLink[] | null
}

const LanguageSwitch = ({ urlTranslations }: LanguageSwitchProps) => {
  const router = useRouter()
  const t = useTranslations()

  const [showLanguageList, setShowLanguageList] = useState(false)

  const handleToggle = useCallback(() => {
    setShowLanguageList(prev => !prev)
  }, [])

  const renderDefaultLanguageMenuItem = useCallback(() => {
    return languageSwitchMenuItems
      .filter(el => el.locale === router.locale)
      .map(menu => (
        <button
          className="c-language-switch d-flex align-items-center justify-content-center position-relative p-0 border-0"
          key={menu.locale}
          type="button"
          onClick={handleToggle}
        >
          <Image
            src={localeToFlag[menu.locale as keyof typeof localeToFlag]}
            width={34}
            height={20}
            className="object-cover"
            alt={`Flag ${menu.label}`}
          />
        </button>
      ))
  }, [handleToggle, router.locale])

  const renderLanguageMenuItem = useCallback(() => {
    return languageSwitchMenuItems
      .filter(el => el.locale !== router.locale)
      .map(menu => {
        let urlTranslation = urlTranslations?.find(
          translation => translation.langcode === menu.locale,
        )?.alias
        if (!urlTranslation || urlTranslation === '/home-jobs') {
          urlTranslation = `/${menu.locale}`
        } else {
          urlTranslation = `/${menu.locale}${urlTranslation}`
        }
        return (
          <li key={menu.locale} className="d-flex">
            <Link
              href={urlTranslation}
              className="c-language-list__cta d-flex justify-content-center align-items-center"
              onClick={e => {
                e.stopPropagation()
              }}
              locale={menu.locale}
            >
              <Image
                src={localeToFlag[menu.locale as keyof typeof localeToFlag]}
                width={34}
                height={20}
                className="object-cover"
                alt={`Flag ${menu.label}`}
              />
              <span className="c-language-list__name">{menu.label}</span>
            </Link>
          </li>
        )
      })
  }, [router.locale, urlTranslations])

  const renderLanguageList = useCallback(() => {
    return showLanguageList ? (
      <div
        className="c-language-list__backdrop position-absolute"
        onClick={handleToggle}
        role="button"
        aria-hidden="true"
      >
        <div className="c-language-list position-absolute">
          <button
            className="c-language-list__close position-absolute d-lg-none"
            aria-label="close"
            type="button"
          >
            <svg
              width="19"
              height="19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#fff"
                d="m5.421 3.614 9.036 9.036-1.808 1.807-9.035-9.035z"
              />
              <path
                fill="#fff"
                d="m14.456 5.42-9.035 9.036-1.808-1.807 9.036-9.036z"
              />
            </svg>
          </button>
          <p className="c-language-list__title text-center">
            {striptags(t.raw('language_list_title'))}
          </p>
          <ul className="c-language-list__list d-lg-block d-flex flex-column align-items-stretch justify-content-center p-0 m-0">
            {renderLanguageMenuItem()}
          </ul>
        </div>
      </div>
    ) : null
  }, [showLanguageList, handleToggle, t, renderLanguageMenuItem])

  return (
    <>
      {renderDefaultLanguageMenuItem()}

      {renderLanguageList()}
    </>
  )
}

export default LanguageSwitch
