import {RefObject, useEffect, useState, TouchEvent} from 'react'

// eslint-disable-next-line import/prefer-default-export
export function useSessionstorage(key: string, initialValue: any) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item =
        typeof window !== 'undefined' && window.sessionStorage.getItem(key)
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      return initialValue
    }
  })
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to sessionStorage.
  const setValue = (value: any) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Save to local storage
      window.sessionStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      // A more advanced implementation would handle the error case
    }
  }
  return [storedValue, setValue]
}

/**
 * // useWindowDimension.ts
 * * This hook returns the viewport/window height and width
 */

export type WindowDimentions = {
  width: number | undefined
  height: number | undefined
}

export function useWindowDimensions(): WindowDimentions {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimentions>({
    width: undefined,
    height: undefined,
  })
  useEffect(() => {
    function handleResize(): void {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return (): void => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return windowDimensions
}

export function getElementDimensions(ref: RefObject<HTMLElement>) {
  const width = ref?.current?.clientWidth
  const height = ref?.current?.clientHeight
  return {width, height}
}

export function getPositionX(event: TouchEvent): number {
  return event.touches[0].clientX
}
